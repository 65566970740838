import { AppUser } from "./user";

export interface BusinessDocument {
    documentID: string;
    filePath: string;
    originalFileName: string;
    documentProfile: number;
    type: number;
    comment: string;
    profileOrder: number;
    isValid: boolean;
    isPass: boolean;
    isReviewed: boolean;
    isAlternateDocRequest: boolean;
    isAlternateDocReturned: boolean;
    status: DocumentStatus;
    profile: number;
    authorID: string;
    author?: AppUser;
    businessApplicationID: string;
    businessApplication: string;
}

export enum BusinessDocumentType {
    CoverLetter,
    BusinessPlan,
    CashflowProjections,
    TradingLicence,
    MemorandumAndArticlesOfAssociation,
    CertificateOfIncorporation,
    IDCopy,
    LeaseAgreement,
    FinancialStatements,
    ITCReport,
    FormR,
    FormT,
    FormJ,
    Additional,
    RecommendationLetter,
    ApprovalLetter
}

export enum DocumentStatus {
    Pending,
    Accepted,
    Rejected,
    Requesting,
}

export enum DocumentProfile {
    Business,
    Legal,
    Banking,
    Forms,
    Additional,
    Approval
}

export interface ProfileDocument {
    id: string;
    filePath: string;
    fileName: string;
    status: string;
    type: BusinessDocumentType;
    authorName: string;
}
