















































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { v4 as uuid } from "uuid";
import agent from "@/helpers/agent";
import {
  BusinessDocumentType,
  DocumentProfile,
  DocumentStatus,
  ProfileDocument,
} from "@/models/businessDocument";
import { namespace } from "vuex-class";

const app = namespace("app");

@Component
export default class BusinessProfile extends Vue {
  @app.Getter authHeader!: any;

  step = 1;
  formR: File | null = null;
  formT: File | null = null;

  profileDocuments: ProfileDocument[] = [];

  loading = true;
  filter = "";
  columns = [
    {
      name: "name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: ProfileDocument) => row.fileName,
      sortable: true,
    },
    {
      name: "author",
      required: true,
      label: "Author",
      align: "left",
      field: (row: ProfileDocument) => row.authorName,
      sortable: true,
    },
    {
      name: "status",
      required: true,
      label: "Status",
      align: "left",
      field: (row: ProfileDocument) => row.status,
      sortable: true,
    },
  ];
  get profile() {
    return DocumentProfile.Additional;
  }
  get additionalType() {
    return BusinessDocumentType.Additional;
  }
  status(status: DocumentStatus) {
    return DocumentStatus[status];
  }
  getFields(type: BusinessDocumentType, profileOrder: number) {
    return [
      {
        name: "type",
        value: type,
      },
      {
        name: "documentProfile",
        value: DocumentProfile.Additional,
      },
      {
        name: "profileOrder",
        value: profileOrder,
      },
      {
        name: "applicationId",
        value: this.$route.query.id as string,
      },
      {
        name: "documentId",
        value: uuid(),
      },
    ];
  }
  getProfileDocuments() {
    if (this.$route.query.id) {
      this.loading = true
      agent.BusinessDocuments.getProfileDocuments(
        this.$route.query.id as string,
        DocumentProfile.Additional
      ).then((documents) => {
        this.loading = false;
        this.profileDocuments = documents;
      });
      this.loading = false;
    }
  }
  mounted() {
    this.getProfileDocuments();
  }
}
