var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pt-xl relative-position"},[_c('h3',{staticClass:"text-h5 text-weight-regular"},[_vm._v(" Banking Documents for "),_c('span',{staticClass:"text-weight-bold"},[_vm._v(_vm._s(_vm.$route.query.businessName))]),_c('span',{staticClass:"text-subtitle1 text-weight-bold block"},[_vm._v(" Reference: "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.$route.query.reference))])])]),_c('q-uploader',{staticStyle:{"width":"100%"},attrs:{"url":("/api/documents/create-multiple-documents/" + (_vm.$route.query.id) + "/" + _vm.profile + "/" + _vm.additionalType),"field-name":"file","label":"Additional Documents","form-fields":_vm.getFields(_vm.additionalType, 1),"headers":_vm.authHeader,"auto-upload":"","multiple":""},on:{"uploaded":function($event){return _vm.getProfileDocuments()}}}),_c('q-table',{staticClass:"q-mt-sm",attrs:{"title":"Uploaded additional documents","filter":_vm.filter,"data":_vm.profileDocuments,"columns":_vm.columns,"row-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [_c('q-input',{attrs:{"borderless":"","dense":"","debounce":"300","placeholder":"Search"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{attrs:{"name":"search"}})]},proxy:true}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('q-btn',{staticClass:"q-ml-md",attrs:{"flat":"","round":"","dense":"","icon":"downloading"},on:{"click":function($event){return _vm.exportData()}}})]},proxy:true},{key:"body",fn:function(props){return [_c('q-tr',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.$router.push({
            path: '/view-documents',
            query: {
              id: props.row.id,
              fileName: props.row.fileName,
              author: props.row.authorName,
              status: _vm.status(props.row.status),
            },
          })}}},[_c('q-td',{key:"name",attrs:{"props":props}},[_vm._v(_vm._s(props.row.fileName))]),_c('q-td',{key:"author",attrs:{"props":props}},[_vm._v(_vm._s(props.row.authorName))]),_c('q-td',{key:"status",attrs:{"props":props}},[_vm._v(_vm._s(_vm.status(props.row.status)))])],1)]}}])}),_c('q-inner-loading',{attrs:{"showing":_vm.loading}},[_c('q-spinner-gears',{attrs:{"size":"50px","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }